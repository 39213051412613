import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ addedClass, addedId, clickFn, disabled, label, type, href, target }) => {
    if(type === 'aTagCTA'){
        return (
            <a
                id={addedId}
                className={addedClass}
                href={href}
                disabled={disabled}
                onClick={clickFn}
                target={target}>
                {label}
            </a>
        );
    }
    else{
        return (
            <button
                id={addedId}
                className={addedClass}
                type={type}
                disabled={disabled}
                onClick={clickFn}>
                {label}
            </button>
        );
    }

};

Button.defaultProps = {
	addedClass: 'btn btn--purple',
	addedId: '',
	clickFn: () => {},
	disabled: false,
	label: null,
	type: 'aTagCTA',
};

Button.propTypes = {
	addedClass: PropTypes.string,
	addedId: PropTypes.string,
	clickFn: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.node,
	type: PropTypes.oneOf(['button', 'reset', 'submit', 'aTagCTA']),
};

export default Button;