import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
import Button from '@components/content/button.jsx';
// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
        <meta name="robots" content="noindex"></meta>
	</>
)

const Index = () => (
	<Layout pageClassName="page-404">
		<SEO title='Page Not Found | Aurinia Alliance&reg;' description='' />
		<div className="wrapper">
			<div className="page-404-content">
				<h1>Sorry, this page does not exist</h1>
				<p>You may have an incorrect URL or you might have arrived at this page by mistake. Let's see if you can find what you're looking for back on the homepage.</p>
				<Button addedClass={'btn btn--purple btn--404'} href={'/'} label={'RETURN TO HOME'}></Button>
			</div>
		</div>
		
	</Layout>
);

export default Index;
